// // src/pages/[...].js
// import React from "react";
// import Search from "../../templates/search";
// import { addTraillingSlash, generateFilters, parseResultsUrl } from "@lib/searchUtils";
// import PropertyDetailTemplateWrap from "@templates/property-detail-temaplte";
// import algoliasearch from "algoliasearch/lite";

// export async function getServerData(context) {
//   const { params } = context; // Extract dynamic params from context
//   const path = "/let/" + params["*"]; // Access the wildcard param
//   const locationObject = {
//     pathname: addTraillingSlash(path) || '', // Ensure it's a string or default to an empty string
//   };


//   // Identify details page by checking for 4-5 digits before the end slash
//   const detailsRegex = /\d{4,5}(?=\/$|$)/;





//   if (detailsRegex.test(path)) {
//     const match = locationObject?.pathname?.match(detailsRegex)
//     const propertyId = match ? match[0] : null;

//     const API_URL = `${process.env.PROPERTY_API_URL}/api/property/${propertyId}`;
//     const AUTH_TOKEN = process.env.PROPERTY_API_TOKEN;

//     const headers = {
//       "Accept-Encoding": "gzip, deflate",
//       "Content-Type": "application/json",
//       Authorization: AUTH_TOKEN,
//     };
//     const body = {
//       "property_id": propertyId,
//       conditions: {},
//       ggfx_results: false,
//     };
//     // Fetch data for Details template
//     const res = await fetch(API_URL, {
//       method: "POST",
//       headers,
//       body: JSON.stringify(body),
//     });

//     const data = await res.json();
//     return { props: { data, template: "details" } };
//   } else if (path.startsWith("/let")) {
//     const pageUrlData = parseResultsUrl(locationObject)
//     const filterQuery = generateFilters({ ...pageUrlData })
//     const API_URL = `${process.env.PROPERTY_API_URL}/api/search`;
//     const AUTH_TOKEN = process.env.PROPERTY_API_TOKEN;
  
//     const headers = {
//       "Accept-Encoding": "gzip, deflate",
//       "Content-Type": "application/json",
//       Authorization: AUTH_TOKEN,
//     };
//     // Fetch data for Search template
//     const sortQuery = (() => {
//       switch (pageUrlData?.sortVal) {
//         case "prod_properties_price_desc": return { price: "desc" };
//         case "prod_properties_price_asc": return { price: "asc" };
//         default: return { modified_on: "desc" };
//       }
//     })();

//     const body = {
//       projects: { area: pageUrlData?.areaVal ? pageUrlData?.areaVal : " ", limit: 2 },
//       // algolia_data: false, // if true, it skips fields, extra_fields params and returns strapi algolia_data field alone
//       filters: filterQuery,
//       sort: sortQuery,
//       page: pageUrlData?.pageVal || 0,
//       limit: "10",
//       content: {
//         component: "property_rent_copy",
//         area: pageUrlData?.areaVal ? pageUrlData?.areaVal : ["all"],
//         building_type: pageUrlData?.buildingVal ? pageUrlData?.buildingVal : "",
//         department: pageUrlData?.departmentVal
//       }
//     };

//     const res = await fetch(API_URL, {
//       method: "POST",
//       headers,
//       body: JSON.stringify(body),
//     });
//     const data = await res.json();
//     return { props: { data, template: "search" } };
  
//   }

//   return { props: { data: null, template: null } };
// }

// const DynamicPage = ({ serverData, location }) => {
//   if (serverData.template === "details") {
//     return <div>404 - Page Not Found</div>
//     // <PropertyDetailTemplateWrap data={serverData.data} location={location} />;
//   } else if (serverData.template === "search") {
//     return <Search serverData={serverData?.data} location={location} />;
//   } else {
//     return <div>404 - Page Not Found</div>;
//   }
// };

// export default DynamicPage;
// src/templates/search.js
import React from "react";

const Search = ({ data }) => {
  return (
    <div>
      <h1>Search let Page</h1>
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
    </div>
  );
};

export default Search;